import { Refresh } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { compact, difference, max, min, xor } from 'lodash';
import { makeAutoObservable } from 'mobx';
import config from '../../../config';
import differenceBetweenObjects from '../../../utils/helpers/differenceBetweenObjects';
import MatchClass from './Match.class';
import MatchCellClass from './MatchCell.class';
import PlaceClass from './Place.class';

const SCHEMA_INIT = {
    name: '',
    players: null,
    type: 'other',
    stagePlayers: 0
};

const SCHEMA = {
    id: null,
    name: '',
    order: null,
    players: null,
    tournament_stage: null,
    type: 'other',
    players_in_order: null
};

const DEFAULT_VALUES = {
    ...SCHEMA,
    matches: null,
    inited: false,
    allowChangePlace: false
};

export default class SubstageClass {
    matches = null;

    places = [];

    constructor(api, stage, values = {}, autoInit = false) {
        this.api = api;
        this.stage = stage;

        const initialValues = { ...SCHEMA, ...values };

        this.initialValues = initialValues;

        Object.entries({ ...DEFAULT_VALUES, ...initialValues }).forEach(([key, value]) => {
            this[key] = value;
        });

        if (autoInit) {
            this.init();
        }

        // reaction(
        //     () => this.matches?.map((m) => m.winner),
        //     (arg, prev) => {
        //         console.log({ arg, prev });
        //     }
        // );

        makeAutoObservable(this);
    }

    get getData() {
        return Object.keys(SCHEMA).reduce((acc, curr) => {
            acc[curr] = this[curr];
            return acc;
        }, {});
    }

    get getPlayoffData() {
        return this.matches;
    }

    get getGroupData() {
        if (!this.matches) {
            return { head: [], rows: [] };
        }
        const head = [
            { text: '№', head: true },
            { text: 'Ф.И. ИГРОКА', head: true },
            ...this.players.map((_, i) => i + 1),
            { text: 'ОЧКИ', head: true },
            { text: '', head: true },
            {
                text: (
                    <>
                        МЕСТО
                        <IconButton size="small" onClick={() => this.loadSubstagePlaces()}>
                            <Refresh />
                        </IconButton>
                    </>
                ),
                head: true
            }
        ];

        const rows = this.players.map((player, i) => ({
            headPlayer: player,
            data: [
                i + 1,
                {
                    ...player,
                    movePlayerDown: () => this.movePlayerDown(player.id),
                    movePlayerUp: () => this.movePlayerUp(player.id)
                },
                ...this.players.map((player_2) =>
                    this.getMatchesByReqests(player, player_2, false)
                ),
                this.getPlayerWinsCount(player),
                this.getPlayerSetsScore(player),
                this.getPlayerPlace(player)
            ]
        }));

        return { head, rows };
    }

    // get getTableData() {
    //     switch (this.stage.stage_type) {
    //         case config.references.olympicStage:
    //             return this.getPlayoffData;
    //         case config.references.groupStage:
    //             return this.getGroupData;
    //         default:
    //             return null;
    //     }
    // }

    get isPlayersInOrderCorrect() {
        if (!this.players_in_order) {
            return false;
        }

        return !difference(
            this.players_in_order,
            this.players.map((player) => player.id)
        ).length;
    }

    get isPlayersInMatchesCorrect() {
        return !!this.players.reduce((acc, cur) => {
            const playerMatches = this.matches.filter((match) => match.request_1?.id === cur.id);
            const isCorrectCur = playerMatches.length === this.players.length - cur.idx - 1;
            return acc * isCorrectCur;
        }, true);
    }

    findPlayersFromParticipants = (ids) => {
        // TODO: Сделать проверку на arrayOf
        if (!this.inited) {
            return ids.map((id) =>
                this.stage.parent.participants.find((player) => id === player.id)
            );
        }
        return this.players;
    };

    init = async (reinit = false) => {
        if ((!this.inited || reinit) && this.id) {
            this.players = this.findPlayersFromParticipants(this.players);
            if (this.players) {
                this.decoratePlayersIdx();
            }
            await this.loadSubstageMatches();
            await this.loadSubstagePlaces();

            this.sortAsPlayersInOrder();
            this.fixPlayersInMatches();
        }
        this.inited = true;
    };

    fixPlayersInMatches = () => {
        if (
            this.stage.stage_type === config.references.groupStage &&
            !this.isPlayersInMatchesCorrect
        ) {
            this.players.forEach((request_1) => {
                this.players.forEach((request_2) => {
                    if (request_1 !== request_2) {
                        const mirrorMatch = this.matches.find(
                            (match) =>
                                match.request_1?.id === request_2?.id &&
                                match.request_2?.id === request_1?.id
                        );

                        if (mirrorMatch && request_1.idx < request_2.idx) {
                            mirrorMatch.swapReqest();
                        }
                    }
                });
            });
        }
    };

    decoratePlayersIdx = () => {
        this.players = this.players.map((player, idx) => ({ ...player, idx }));
    };

    getPlayerMatches = (request) =>
        this.matches.filter(
            (match) => match.request_1?.id === request.id || match.request_2?.id === request.id
        );

    getPlayerWinsCount = (request) => {
        const matches = this.matches.filter(
            (match) => match.winner && match.winner.id === request.id
        );

        return matches.length || 0;
    };

    getPlayerSetsScore = (request) => {
        const matches = this.getPlayerMatches(request);
        const score = matches.reduce(
            (acc, cur) => {
                const score = cur.matchScore;
                if (cur.request_1?.id === request.id) {
                    acc.win += score.p1_score;
                    acc.lost += score.p2_score;
                } else {
                    acc.win += score.p2_score;
                    acc.lost += score.p1_score;
                }
                return acc;
            },
            { win: 0, lost: 0 }
        );

        return `${score.win}:${score.lost}`;
    };

    getPlayerPlace = (request) => {
        const place = this.places?.find((el) => el.request_id === request.id);
        return place;
    };

    fixEmptyMatches = () => {
        this.matches.forEach((match) => {
            if (!match.request_1 && !match.request_2 && match.id) {
                this.api.deleteMatch(match.id);
            }
        });
    };

    getMatchesByReqests = (request_1, request_2, withCreate = false) => {
        if (request_1 === request_2) {
            return null;
        }
        const match = this.matches.find(
            (match) =>
                match.request_1?.id === request_1?.id && match.request_2?.id === request_2?.id
        );

        const mirrorMatch = this.matches.find(
            (match) =>
                match.request_1?.id === request_2?.id && match.request_2?.id === request_1?.id
        );

        return (
            (match &&
                new MatchCellClass({
                    match
                })) ||
            (mirrorMatch &&
                new MatchCellClass({
                    match: mirrorMatch,
                    isMirror: true
                })) ||
            (withCreate &&
                new MatchCellClass({
                    match: this.addMatch({ request_1, request_2 }),
                    isMirror: request_1.idx > request_2.idx,
                    saveAsMirror: request_1.idx > request_2.idx
                })) ||
            null
        );
    };

    setData = (data) => {
        Object.entries(data).forEach(([key, value]) => this.updateField(key, value));
        return this.getData;
    };

    updateField = (key, value) => {
        this[key] = value;
        return this[key];
    };

    loadThisSubStage = async () => {
        if (this.id) {
            const substage = this.api.getStageSubstage({
                stageId: this.stage.id,
                substageId: this.id
            });
            return this.setData(substage);
        }

        console.warn('Не задан id субстейджа');
        return null;
    };

    loadSubstageMatches = async () => {
        if (this.id) {
            const matches = (
                await this.api.getSubstageMatches({
                    substageId: this.id
                })
            ).map(
                (match) =>
                    new MatchClass(this.api, match, () => {
                        if (this.matches && this.matches.every((m) => !!m.winner)) {
                            console.log(123);
                            this.loadSubstagePlaces();
                        }
                    })
            );
            matches.forEach((match) => this.assignMatchWithPlayers(match));

            return this.setData({
                matches
            });
        }
        console.warn('Не задан id субстейджа');
        return null;
    };

    addMatch = async ({ request_1, request_2 }) => {
        const newMatch = new MatchClass(this.api, {
            request_1,
            request_2,
            tournament_stage: this.stage.id,
            tournament_substage: this.id
        });

        this.matches = [...this.matches, newMatch];

        return newMatch;
    };

    setSubstagePlaces = (places) => {
        this.places = places
            .filter((place) => this.players.find((player) => place.request_id === player.id))
            .map((place) => new PlaceClass(this.api, place, this));
    };

    loadSubstagePlaces = async () => {
        const params = {
            substage_id: this.id
        };
        const places = await this.api.getPlaces({ params });
        this.places = places.map((place) => new PlaceClass(this.api, place, this));
    };

    assignMatchWithPlayers = (match) => {
        if (match.constructor === MatchClass) {
            const request_1 =
                this.stage.players.find((player) => player.id === match.request_1) || null;
            const request_2 =
                this.stage.players.find((player) => player.id === match.request_2) || null;
            const winner = this.stage.players.find((player) => player.id === match.winner) || null;

            match.assignWithPlayers(request_1, request_2, winner);
        }
    };

    sortAsPlayersInOrder = () => {
        if (!this.isPlayersInOrderCorrect) {
            this.setPlayersInOrder(this.players.map((player) => player.id));
        } else {
            this.players = this.players_in_order.map((id) =>
                this.players.find((player) => player.id === id)
            );
        }
        this.decoratePlayersIdx();
    };

    setPlayersInOrder = (newOrder) => {
        this.players_in_order = newOrder;
        this.sortAsPlayersInOrder();
    };

    movePlayer = async (id, to) => {
        const newOrder = [...this.players_in_order];
        const pos = this.players_in_order.findIndex((inOrderId) => inOrderId === id);
        const swapPos = max([min([pos + to, this.players_in_order.length - 1]), 0]);
        const swapEl = this.players_in_order[swapPos];
        newOrder[swapPos] = id;
        newOrder[pos] = swapEl;

        await this.changePlayersOrder(newOrder);
        this.fixPlayersInMatches();
    };

    movePlayerDown = (id) => {
        this.movePlayer(id, 1);
    };

    movePlayerUp = (id) => {
        this.movePlayer(id, -1);
    };

    changePlayersOrder = async (newOrder) => {
        try {
            const patched = await this.api.patchStageSubstage({
                stageId: this.stage.id,
                substageId: this.id,
                payload: { players_in_order: newOrder }
            });
            this.setPlayersInOrder(newOrder);
            this.initialValues = { ...this.initialValues, players_in_order: newOrder };
            return patched;
        } catch (error) {
            //
        }
    };

    uploadSubstage = async () => {
        if (this.id) {
            const { players: diffPlayers, ...diff } = differenceBetweenObjects(
                this.getData,
                this.initialValues
            );

            const payload = { ...diff };

            if (diffPlayers) {
                const diffPlayerIds = diffPlayers.map((player) => player.id);

                const d = xor(diffPlayerIds, this.initialValues.players);
                if (d.length) {
                    payload.players = diffPlayerIds;
                }
            }

            if (!Object.keys(payload).length) {
                return this.getData;
            }

            if (payload.players) {
                payload.players = this.players.map((player) => player.id);
            }
            const substage = await this.api.patchStageSubstage({
                stageId: this.stage.id,
                substageId: this.id,
                payload
            });
            if (substage) {
                if (payload.players) {
                    this.init(true);
                }
                this.initialValues = { ...substage, players: this.players };
                return this.setData({ ...substage, players: this.players });
            }
            this.setData({ ...this.initialValues });
            return;
        }
        const substage = await this.api.postStageSubstage({
            stageId: this.stage.id,
            payload: {
                ...this.getData,
                players: this.players.map((player) => player.id)
            }
        });
        if (substage) {
            this.initialValues = { ...substage, players: this.players };
            this.init(true);
            return this.setData({ ...substage, players: this.players });
        }
    };

    deleteSubstage = async () => {
        if (this.id) {
            await this.api.deleteStageSubstage({ stageId: this.stage.id, substageId: this.id });
            this.stage.deleteSubstage(this.id);
        }
    };

    setAllowChangePlace = (value) => {
        this.allowChangePlace = value;
    };

    get playersWithoutMatches() {
        return compact(
            this.players?.length && this.matches?.length && this.players?.length
                ? this.players.map((player) =>
                      this.matches.find(
                          (match) =>
                              match.request_1?.id === player.id || match.request_2?.id === player.id
                      )
                          ? null
                          : player
                  )
                : []
        );
    }

    kickPlayersFromMatches = (requestIds = []) => {
        requestIds.forEach((requestId) => {
            this.matches
                .filter(
                    (match) =>
                        match.request_1?.id === requestId || match.request_2?.id === requestId
                )
                .forEach((match) => {
                    if (match.request_1?.id === requestId) {
                        match.setData({
                            request_1: null
                        });
                    } else if (match.request_2?.id === requestId) {
                        match.setData({ request_2: null });
                    }
                    match.inputOnChange(null, '');
                    match.fixXMatches();
                    match.uploadMatch();
                });
        });
    };
}

export { SCHEMA, SCHEMA_INIT };
